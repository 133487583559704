import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo";
import MiniHero from "../components/mini-hero"
import Info from "../components/info";
import AboutMore from "../components/about-more";
import Work from "../components/work";
const description  = 'Our services range from design to market, digital solutions and business process improvement'

const heading ='A dedicated team to support you'
const pagedescription = 'Our dedication to our customer is our strength'
const detail = 'In 2015, as a team working in technology, our idea was that for organisations to succeed in a technology based economy, they need to improve the development of their digital capability way more than just the technology. The structure, culture and the people is more important that the product. We setup an organisation to value people over products and this yields better people and better products.it was a win-win.'
export default () => (
    
    <Layout>
        <SEO title="Business services providing best solutions" description={description} keywords={[`software`, `business`, `solutions`]} />
        <MiniHero title='Our Services' />
        <AboutMore title='What we offer' heading={heading} description={pagedescription} detail={detail} />
        <Work />
        <Info />
    </Layout>

    );
