import React from "react"

const AboutMore = ({title, heading, description, detail}) => {
  return (
    <>
      <section className="about_part">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="about_img">
                <img src="img/about_img_1.png" className="img_1" alt="" />
                <img src="img/about_img_2.png" className="img_2" alt="" />
                <h2>
                  10 Years Working <br />
                  Experience
                </h2>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-4">
              <div className="about_text">
              <h5>{title}</h5>
              <h2>{heading}</h2>
                <p>
                  {detail}
                </p>
                <a href="/about" className="btn_1">
                  learn more <i className="ti-angle-right"></i>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutMore
