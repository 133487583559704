import React from "react"

const Work = () => {
  return (
    <>
      <section class="our_latest_work section_padding">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-5">
              <div class="section_tittle text-center">
                <h2>The heart of what we do</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="single_work">
                <div class="row align-items-center">
                  <div class="offset-lg-1 col-lg-4 col-md-6">
                    <div class="single_work_demo">
                      <h5>Solution Architecture</h5>
                      <h3>
                        Accelerate business transformation with creative
                        solutions
                      </h3>
                      <p>
                        The digital transformation journey takes many paths and
                        can impact your business in different ways. You need to
                        balance strategy with execution, use technology to
                        accelerate your innovation agenda, integrate
                        intelligence, and harness the cloud to improve employee
                        and customer experiences.{" "}
                      </p>
                      <a href="project_details.html" class="btn_3">
                        learn more <span class="flaticon-slim-right"></span>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="offset-lg-1 col-lg-6 col-md-6">
                    <div class="demo_img">
                      <img src="img/solution_cubes.jpg" alt="Architecting solutions" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="single_work">
                <div class="row align-items-center">
                  <div class="col-lg-6 col-md-6">
                    <div class="demo_img">
                      <img src="/img/pc_analytics.jpg" alt="Business Analytics" />
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="single_work_demo">
                      <h5>Data Analytics</h5>
                      <h3>Helping you make sense of your data</h3>
                      <p>
                        We work with your teams to understand your exact needs
                        and requirements, allowing us to develop the most
                        intuitive dashboards to deliver real actionable insight.
                        With our partnership we deliver dashboards in sprints,
                        following the bespoke roadmap we have developed together
                        with you.
                      </p>
                      <a href="project_details.html" class="btn_3">
                        learn more <span class="flaticon-slim-right"></span>{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="single_work">
                <div class="row align-items-center">
                  <div class="offset-lg-1 col-lg-4 col-md-6">
                    <div class="single_work_demo">
                      <h5>Digital Transformation</h5>
                      <h3>
                        Digital technology strategy and innovation drive
                        competitive advantage
                      </h3>
                      <p>
                        We assist organizations to explore how emerging and
                        validated technologies can align with and enable business
                        transformation. We support various organizations with technology
                        strategies and approaches for including Business Intelligence
                        , Design Thinking and Digital Idea generation.
                      </p>
                      <a href="project_details.html" class="btn_3">
                        learn more <span class="flaticon-slim-right"></span>{" "}
                      </a>
                    </div>
                  </div>
                  <div class="offset-lg-1 col-lg-6 col-md-6">
                    <div class="demo_img">
                      <img src="/img/leaves_transformation.jpg" alt="Business Transformation" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Work
