import React from "react"

const Info = () => {
  return (
    <>
      <section className="cta_part">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="cta_part_iner">
                <div className="cta_part_text">
                  <p> Our Features</p>
                  <h1>Your business is our business</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="service_part section_bg_2 section_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single_service_part">
                <div className="single_service_part_iner">
                  <span className="ti-mobile"></span>
                  <h3>Agile at Heart</h3>
                  <p>
                  Embracing the agile philosophy and values at its core, from its people and culture{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_service_part">
                <div className="single_service_part_iner">
                  <span className="ti-layers"></span>
                  <h3>Customer First</h3>
                  <p>
                    Always thinking of innovative ideas to improve customer experience and beating expectations.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_service_part">
                <div className="single_service_part_iner">
                  <span className="ti-lock"></span>
                  <h3>Delivery is key</h3>
                  <p>
                    Our business is getting your product to market in the time that suits you. Your time is valueable to us.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_service_part">
                <div className="single_service_part_iner">
                  <span className="ti-headphone"></span>
                  <h3>People</h3>
                  <p>
                    Our team and culture is our key value and that's one of the key things that make us excel in everything that we do.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Info
